// react
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
// third-party
import PropTypes from 'prop-types';

// data stubs
import products from '../../data/shopProducts';
import theme from '../../data/theme';
import { sidebarClose } from '../../store/sidebar';
import PageHeader from '../shared/PageHeader';

// application
import CategorySidebar from './CategorySidebar';
import ProductsView from './ProductsView';

function mapProductToEEC(product) {
    return {
        category: product.category + '/' + product.subcategory,
        gameId: product.id,
        image: window.location.origin + product.images[0],
        launchUrl: window.location.origin + '/shop/play/' + product.id,
        name: product.name,
        poster_tall_hires: window.location.origin + product.poster_tall_hires[0],
        thumbnail: window.location.origin + product.images[0],
        vendor: product.brand,
        poster_wide: window.location.origin + product.poster_wide[0],
    };
}

function shuffle(array) {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}
function ShopPageCategory(props) {
    const { columns, productCategory, productSubCategory, sidebarPosition, viewMode } = props;

    var filteredProducts = products;

    var header = 'All';

    var breadcrumb = [
        { title: 'Home', url: '' },
        { title: header, url: '' },
    ];
    if (productCategory) {
        header = productCategory;
        breadcrumb = [
            { title: 'Home', url: '' },
            { title: productCategory, url: '' },
        ];
        filteredProducts = products.filter(function (product) {
            return product.category === productCategory;
        });
    }
    if (productSubCategory) {
        header = productSubCategory;
        breadcrumb = [
            { title: 'Home', url: '' },
            { title: productCategory, url: '' },
            { title: productSubCategory, url: '' },
        ];
        filteredProducts = products.filter(function (product) {
            return product.subcategory === productSubCategory;
        });
    }

    filteredProducts = shuffle(filteredProducts);

    let content;

    const offcanvas = columns === 3 ? 'mobile' : 'always';

    // send google enhanced ecommerce impressions
    if (!window.dataLayer) window.dataLayer = [];
    const eecProducts = filteredProducts.map(mapProductToEEC);
    window.dataLayer.push({
        event: 'gameImpressions',
        products: eecProducts,
    });

    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">
                    <ProductsView
                        products={filteredProducts}
                        layout={viewMode}
                        grid={`grid-${columns}-full`}
                        limit={15}
                        offcanvas={offcanvas}
                    />
                </div>
                {<CategorySidebar offcanvas={offcanvas} />}
            </div>
        );
    } else {
        const sidebar = <div className="shop-layout__sidebar">{<CategorySidebar offcanvas={offcanvas} />}</div>;

        content = (
            <div className="container">
                <PageHeader header={header} breadcrumb={breadcrumb} />
                <div id="frosmo-category-reco-1"></div>
                <div id="frosmo-category-reco-2"></div>
                <div id="frosmo-category-reco-3"></div>
                <div id="frosmo-category-reco-4"></div>
                <div id="frosmo-category-reco-5"></div>

                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">
                            <ProductsView
                                products={filteredProducts}
                                layout={viewMode}
                                grid="grid-3-sidebar"
                                limit={15}
                                offcanvas={offcanvas}
                            />
                        </div>
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Shop Category Page — ${theme.name}`}</title>
            </Helmet>

            {content}
        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,

    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),

    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    sidebarPosition: 'start',
    viewMode: 'grid',
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
});

const mapDispatchToProps = {
    sidebarClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
